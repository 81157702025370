body {
    margin: 0;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5385;
    color: #333;
    text-align: left;
    background-color: #eee
}

.mt-50 {
    margin-top: 50px
}

.mb-50 {
    margin-bottom: 50px
}

.card-inv {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .1875rem
}

.card-img-actions {
    position: relative
}

.card-body-inv {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.5rem;
    text-align: center
}

.card-body-inv2 {
    /* -ms-flex: 1 1 auto; */
    /* flex: 1 1 auto; */
    padding: 1.5rem;
    text-align: right
}

/* .card-title {
    margin-top: 10px;
    font-size: 17px
} */

.invoice-color {
    color: green !important
}

.card-header-inv {
    padding: .1rem 1.5rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .02);
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

/* a {
    text-decoration: none !important
} */

/* .btn-light {
    color: #333;
    background-color: #fafafa;
    border-color: #ddd
} */

.header-elements-inline-inv {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

@media (min-width: 768px) {
    .wmin-md-400 {
        min-width: 400px !important
    }
}

@media (max-width: 575px) {
    #paid-to-left{
        display: block !important;
    }
    #paid-to{
        display: none !important;
    }
}

/* .btn-primary {
    color: #fff;
    background-color: #2196f3
} */

/* .btn-labeled>b {
    position: absolute;
    top: -1px;
    background-color: blue;
    display: block;
    line-height: 1;
    padding: .62503rem
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.preview {
  /* padding: 1rem; */
  margin-top: 1rem;
}

#main-navbar{
  padding: 0;
}

.input-group .input-group-btn {
  right: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mobile-searchBox{
  display: none;
}

.nav-sticky-custom{
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 20;
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}


/* @import 'node_modules/react-datepicker/src/stylesheets/datepicker.scss'; */

.react-datepicker__header {
  text-align: center;
  background-color: #3e82cf;
  border-bottom: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 8px;
  position: relative;

}

#prod-heading{
  white-space: nowrap;      /*keep text on one line */
  overflow: hidden;         /*prevent text from being shown outside the border */
  text-overflow: ellipsis;  /*cut off text with an ellipsis*/
}

#des-text{
  margin-bottom: 0;
  text-align: justify;
}

#des-box {
 
  /* border: 0.5px solid rgba(0,0,0,0.1);; */
  padding: 5px;
  height: 8em;

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

#des-box-doctor {
 
  /* border: 0.5px solid rgba(0,0,0,0.1);; */
  padding: 2px;
  height: 4em;

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

#des-text-service{
  margin-bottom: 0;
  text-align: justify;
}

#des-box-service {
 
  /* border: 0.5px solid rgba(0,0,0,0.1);; */
  padding: 0px;
  height: 12em;

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}

#disease-text{
  margin-bottom: 0;
  text-align: justify;
}

/* #nav-sticky-custom{
  position: sticky;
  top: 0;
} */

#disease-box {
 
  /* border: 0.5px solid rgba(0,0,0,0.1);; */
  padding: 0px;
  height: 12em;

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

#disease-button {     
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;        
}
#hww-btn{
  /* font-size: 10px; */
  font-weight: 500;
  /* display: inline-block; */
}

@media (max-width: 600px){
  #img-diseases{
    padding-left: 20px;
    padding-right: 20px;
    height: 80%;
  }

  #meet-our-doctors-h3, #diseases-h3, #how-we-work-h3,#whatourclient-h3{
    padding-left: 20px;
  }
   /* #meet-our-doctors-card{
    margin-left: 10px;
  }  */
  
  #meet-our-doctors-hr, #how-we-work-hr{
    display: none;
  }
  
  /* #diseases-hr{
    padding-left: 20px;
  } */
}

@media (max-width: 930px){
  #service-search{
    display: none;
  }
  #doctors-on-call-heading{
    width: 150%;
  }
  #service-search-mobile{
    display: inline-block !important;
  }
}
@media (max-width: 400px){
  .navitem-hww{
    width: 24%;
    
  }
  #hww-btn{
    font-size: 10px;
    /* font-weight: bold; */
    display: inline-block;
  }
}

.searchInput::-webkit-search-cancel-button{
  display: none;
}

@media (max-width: 992px){

  
  .page-title{
    padding-top: 10px !important;
    padding-bottom: 0px !important;
    margin-bottom: 10px !important;
  }
  .mobile-searchBox{
    display: inline-block;
    width: "100%";
    position: absolute;
    padding-top: 62px;
  }

  .mobile-menu {
    height: 100vh;
    /* max-height: 100vh; */
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling:touch;
    z-index: 100;
  }

  .main-body{
    position: relative;
    padding-top: 110px;
  }

  #cart-text-label{
    margin-top: -5px !important;
  }
  #cart-text-label-link{
    padding-bottom: 0 !important;
  }

  /* #site-branding{
    padding-left: 0;
  } */

  .topbar-stuck  {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    height: 62px;
    background-color: white;
  }
  /* .mob-searchbar-stuck  {
    position: fixed;
    top: 63;
    left: 0;
    width: 100%;
    z-index: 4;
    height: 62px;
    background-color: white;
  } */

  /* #site-branding-logo{
    display: none !important;
  } */


}

/* .site-header .navbar {
 
  background-color: #9eca62 !important;
  color: #ffffff !important;
} */

.navbar-stuck .navbar {
  /* position: fixed;
  top: 0;
  left: 0; */
  background-color: #ffffff !important;
  color: black !important;
  /* box-shadow: 0 7px 30px -6px rgba(0, 0, 0, 0.15); */
}

/* .service-on-hover:hover{
  background-color: #91c54a !important;
  
} */

/* .menu-color-custom {
  color: blue ;
} */

.service-on-hover:hover  > i{
  color: #505050 !important;
  /* color: white !important; */
  -webkit-transform: translateY(0px) !important;
      -ms-transform: translateY(0px) !important;
          transform: translateY(0px) !important;
}
.service-on-hover{
  background-color: #505050;
  /* #eee !important; */
}

.cart-product-img{
  width: 130px;
}

@media (max-width: 700px){

  #prod-title{
    font-size: 26px;
  }
  #prod-price{
    font-size: 14px ;
  }

  .cart-table thead{
    display: none;
  }

  /* #div-quantity{
    justify-content: center;
  } */

  #cart-quantity{
    width: 70%;
    text-align: center;
    margin-left: 15%;
  }

  label[for="weight"] { 
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

label[for="quantity"] { 
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
  .cart-table, .cart-table tbody, .cart-table tr, .cart-table td{
    display: block;
    width: 100%;

  }

  .cart-table tr{
    
    margin-bottom: 15px;
    background-color: #eee;
    border: 1px solid rgba(193, 212, 224, 0.125);
    border-radius: .25rem;
  }

  .cart-table tbody tr{
    border-top:none;
  }

  .cart-table tbody tr td{
    text-align: right;
    padding-left: 50%;
    position: relative;
    
  }

  .cart-table td::before{
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: 450;
    font-size: 14px;
    text-align: left;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    
  }

  .cart-product-img{
    width: 100px;
  }


 
}

